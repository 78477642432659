import React, { Component } from "react";
import validateWaterfowlWarmupForm from "../../validation/validateWaterfowlWarmupForm";
import isEmpty from "../../util/isEmpty";
import centsToDollarFormat from "../../util/centsToDollarFormat";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { updateFormData, setReturnURL } from "../../actions/formActions";
import axios from "axios";
import { PulseLoader } from "react-spinners";
import { css } from "@emotion/core";
import NumberFormat from "react-number-format";
const override = css`
  display: inline;
  margin: 15px;
`;

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: true,
      formOpen: false,
      teamSpotsRemaining: 0,
      spotsRemaining: 0,
      fields: {
        formName: "2024 Waterfowl Warmup Registration",
        firstName: "",
        lastName: "",
        teamName: "",
        email: "",
        phoneNumber: "",
        sponsorshipLevel: "",
        secondTeam: "",
        showAddSecondTeam: false,
      },
      totalAmount: 0,
      errors: {},
      stripeTotalAmount: 5,
      stripeMetaData: {
        name: "",
      },
      stripeDescription: "",
      redirect: false,
    };
  }

  componentDidMount() {
    console.log("Component did mount!");
    axios
      .get(REACT_APP_API_URL + "inventory/WW2024TE")
      //* Evaluate received response
      .then((res) => {
        this.setState({
          processing: false,
          formOpen: res.data.available,
          teamSpotsRemaining: res.data.itemsRemaining,
        });
        console.log(res);
      });

    //* Update state if values exsist.
    if (this.props.form) {
      const formData = this.props.form;
      const formFieldState = this.state.fields;

      if (
        typeof formData.formName !== "undefined" &&
        formData.formName === formFieldState.formName
      ) {
        //* Create a complete object from current state
        let newObj = {};
        Object.keys(formFieldState).forEach((item) => {
          newObj[item] = formFieldState[item];
        });

        //* Update key data if within the prop object
        Object.keys(formData).forEach((item) => {
          if (newObj.hasOwnProperty(item)) {
            newObj[item] = formData[item];
          }
        });

        //* Update the state from the updated object
        this.setState((prevState, props) => ({
          fields: newObj,
        }));
      } //* TODO: Add Redirect to error page
    }
    setTimeout(() => {
      this.updateRegistrationCost();
    }, 200);
  }

  //* Form functions
  onChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(
      (prevState) => ({
        fields: {
          ...prevState.fields,
          [name]: value,
        },
      }),
      () => {
        this.updateAddSecondTeam();
      }
    );
  };

  updateAddSecondTeam = () => {
    var showAddSecondTeam;
    var secondTeam;

    if (this.state.fields.sponsorshipLevel !== "") {
      showAddSecondTeam = true;
      secondTeam = this.state.fields.secondTeam;
    } else {
      showAddSecondTeam = false;
      secondTeam = false;
    }

    console.log(this.state.fields.secondTeam);
    this.setState(
      (prevState) => ({
        fields: {
          ...prevState.fields,
          showAddSecondTeam: showAddSecondTeam,
          secondTeam: secondTeam,
        },
      }),
      () => {
        this.updateRegistrationCost();
      }
    );
  };

  updateRegistrationCost = () => {
    console.log("updateRegistrationCost fired!");

    var totalCost = 0;
    var sponsorshipCost = 100000;
    var secondTeamCost = 0;

    // Create a switch statement where the value of the sponsorship level dictates the cost of the registration
    switch (this.state.fields.sponsorshipLevel) {
      case "1":
        sponsorshipCost = 300000;
        break;
      case "2":
        sponsorshipCost = 400000;
        break;
      case "3":
        sponsorshipCost = 500000;
        break;
      default:
        sponsorshipCost = 0;
        break;
    }

    console.log(sponsorshipCost);

    if (this.state.fields.secondTeam) {
      secondTeamCost = 200000;
    }

    totalCost = sponsorshipCost + secondTeamCost;

    this.setState({
      totalAmount: totalCost,
    });
  };

  validateForm = () => {
    const validationCheck = validateWaterfowlWarmupForm(this.state);
    let formIsValid = false;

    //* Reset Field Error State
    this.setState({ errors: {} });

    //* Perform Validation
    if (validationCheck.formIsValid) {
      formIsValid = true;
    } else {
      if (!isEmpty(validationCheck.errors)) {
        this.setState({
          errors: validationCheck.errors,
        });
      }
    }
    return formIsValid;
  };

  createPaymentSummary = () => {
    var sponsorshipType;
    var sponsorshipCost;
    var secondTeam;

    switch (this.state.fields.sponsorshipLevel) {
      case "1":
        sponsorshipType = "Bronze";
        sponsorshipCost = 300000;
        break;
      case "2":
        sponsorshipType = "Silver";
        sponsorshipCost = 400000;
        break;
      case "3":
        sponsorshipType = "Gold";
        sponsorshipCost = 500000;
        break;
      default:
        sponsorshipType = 0;
        break;
    }

    secondTeam = "";

    var txtSummary = `Waterfowl Warmup ${sponsorshipType} Level Team Entry for ${this.state.fields.firstName} ${this.state.fields.lastName}. team: ${this.state.fields.teamName}. e: ${this.state.fields.email}. ph: ${this.state.fields.phoneNumber}.`;

    const paymentSummary = [
      {
        itemName: txtSummary,
        itemQuantity: 1,
        itemTotal: sponsorshipCost,
        itemCode: "WW2024TE",
      },
    ];

    if (this.state.fields.secondTeam) {
      const discountTeam = {
        itemName: "Discounted Team Entry",
        itemQuantity: 1,
        itemTotal: 200000,
        itemCode: "WW2024DTE",
      };

      paymentSummary.push(discountTeam);
    }

    return paymentSummary;
  };

  handleSubmit = (e) => {
    //* Prevent default form action.
    e.preventDefault();

    //* Validate the form
    if (this.validateForm()) {
      //* Create new istance of the field object and pass to parent func
      let updateObj = Object.assign({}, this.state.fields);

      console.log(this.createPaymentSummary());

      //* Create Payment Summary
      updateObj["paymentSummary"] = this.createPaymentSummary();
      updateObj["totalAmount"] = this.state.totalAmount;
      updateObj["redirect"] = "/waterfowlwarmup";

      this.props.updateFormData(updateObj);
      this.props.setReturnURL("https://www.ab-conservation.com/events/waterfowl-warmup/");
      this.setState({ redirect: true });
    }
  };

  render() {
    return (
      <div>
        {/* Form redirect */}
        {this.state.redirect && <Redirect to="/payment" push />}

        <form onSubmit={this.handleSubmit}>
          {/* Form Title */}
          <div className="form-group row mb0">
            <div className="col-sm-12">
              <h2>{this.state.fields.formName}</h2>
              <p>
                Register for this year's Waterfowl Warmup by completing the registration form below.
                One name and contact information from the team is required. Select your sponsorship
                tier from the drop-down menu.
              </p>

              <p>
                Check the box if you are registering two teams for a discount on the second team.
              </p>

              <p>
                For more information or to add members to your team, please contact{" "}
                <a href="mailto:laura.volkman@ab-conservation.com">
                  laura.volkman@ab-conservation.com.
                </a>
              </p>
            </div>
          </div>

          <hr />

          {/* Show or Hide form based on inventory */}

          {this.state.processing ? (
            <PulseLoader
              css={override}
              sizeUnit={"px"}
              size={10}
              color={"#3775e4"}
              loading={this.state.processing}
            />
          ) : (
            <div>
              {this.state.formOpen ? (
                <div>
                  <div>
                    <div className="form-group row mb0">
                      <label htmlFor="firstName" className="col-sm-4 col-form-label">
                        <span className="requiredFields">* </span>First Name:
                      </label>
                      <div className="col-sm-8 mb0">
                        <input
                          name="firstName"
                          type="text"
                          className="form-control mb5"
                          placeholder="Enter your first name."
                          value={this.state.fields.firstName}
                          onChange={this.onChange}
                        />
                        {this.state.errors.firstName && (
                          <div className="alert-danger alert">
                            <span className="errorMsg">{this.state.errors.firstName}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="form-group row mb0">
                      <label htmlFor="lastName" className="col-sm-4 col-form-label">
                        <span className="requiredFields">* </span>Last Name:
                      </label>
                      <div className="col-sm-8 mb0">
                        <input
                          name="lastName"
                          type="text"
                          className="form-control mb5"
                          placeholder="Enter your last name."
                          value={this.state.fields.lastName}
                          onChange={this.onChange}
                        />
                        {this.state.errors.lastName && (
                          <div className="alert-danger alert">
                            <span className="errorMsg">{this.state.errors.lastName}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="form-group row mb0">
                      <label htmlFor="teamName" className="col-sm-4 col-form-label">
                        <span className="requiredFields">* </span>Team Name:
                      </label>
                      <div className="col-sm-8 mb0">
                        <input
                          name="teamName"
                          type="text"
                          className="form-control mb5"
                          placeholder="Enter your last name."
                          value={this.state.fields.teamName}
                          onChange={this.onChange}
                        />
                        {this.state.errors.teamName && (
                          <div className="alert-danger alert">
                            <span className="errorMsg">{this.state.errors.teamName}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="form-group row mb0">
                      <label htmlFor="email" className="col-sm-4 col-form-label">
                        <span className="requiredFields">* </span>Email:
                      </label>
                      <div className="col-sm-8 mb0">
                        <input
                          name="email"
                          type="text"
                          className="form-control mb5"
                          placeholder="Please enter an email."
                          value={this.state.fields.email}
                          onChange={this.onChange}
                        />
                        {this.state.errors.email && (
                          <div className="alert-danger alert">
                            <span className="errorMsg">{this.state.errors.email}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="form-group row mb0">
                      <label htmlFor="phoneNumber" className="col-sm-4 col-form-label">
                        <span className="requiredFields">* </span>Phone Number:
                      </label>
                      <div className="col-sm-8 mb0">
                        <input
                          name="phoneNumber"
                          type="text"
                          className="form-control mb5"
                          placeholder="Enter your phone number."
                          value={this.state.fields.phoneNumber}
                          onChange={this.onChange}
                        />
                        {this.state.errors.phoneNumber && (
                          <div className="alert-danger alert">
                            <span className="errorMsg">{this.state.errors.phoneNumber}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-group row mb0">
                    <label htmlFor="sponsorshipLevel" className="col-sm-4 col-form-label">
                      <span className="requiredFields">*</span> Sponsorship Level:
                    </label>
                    <div className="col-sm-8">
                      <select
                        name="sponsorshipLevel"
                        className="form-control mb5"
                        value={this.state.fields.sponsorshipLevel}
                        onChange={this.onChange}
                      >
                        <option value="">--Choose--</option>
                        <option value={1}>$3000 - Bronze Sponsor</option>
                        <option value={2}>$4000 - Silver Sponsor</option>
                        <option value={3}>$5000 - Gold Sponsor</option>
                      </select>
                      {this.state.errors.sponsorshipLevel && (
                        <div className="alert-danger alert">
                          <span className="errorMsg">{this.state.errors.sponsorshipLevel}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  {this.state.fields.showAddSecondTeam && (
                    <div>
                      <hr />
                      <div>
                        <span className="checkboxMultilineIndent">
                          <input
                            name="secondTeam"
                            type="checkbox"
                            checked={this.state.fields.secondTeam}
                            onChange={this.onChange}
                          />
                          <span>
                            {` `}Add a second team for $2000 (You will be contacted for details on
                            your second team).
                          </span>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <strong>Registration for this event is full.</strong>
                </div>
              )}
            </div>
          )}

          {/* Display Total Amount */}
          <div className="form-group row">
            <div className="col-sm-12">
              <hr />
              <h2>Total: {centsToDollarFormat(this.state.totalAmount)}</h2>
              {this.state.errors.totalAmount && (
                <div className="errorMsg">{this.state.errors.totalAmount}</div>
              )}
            </div>
          </div>

          {/* Submit button */}
          <div className="form-group row">
            <div className="col-sm-12">
              {!this.state.processing && (
                <button className="btn btn-primary" disabled={this.state.submitDisabled}>
                  Make Payment
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.form.formData,
  };
};

export default connect(
  mapStateToProps,
  { updateFormData, setReturnURL }
)(Template);
