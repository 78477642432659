import React, { Component } from "react";
import { BrowserRouter, Switch } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store";

import { Route } from "react-router-dom";
import DefaultLayout from "./layout/DefaultLayout";
import Checkout from "./components/Checkout";
import TemplateForm from "./components/forms/TemplateForm";
import NoviceShootForm from "./components/forms/NoviceShootForm";
import TPFGeneralForm from "./components/forms/TPFGeneralForm";
import DonationForm from "./components/forms/DonationForm";
import WaterfowlWarmupForm from "./components/forms/WaterfowlWarmupForm";
import AnniversaryMerchandisePackForm from "./components/forms/AnniversaryMerchandisePackForm";
import DogCalendarForm from "./components/forms/DogCalenderForm";
import PheasantForm from "./components/forms/PheasantForm";
import NWSGCForm from "./components/forms/NWSGCForm";
import Error from "./components/Error";
import Success from "./components/Success";
import { updateFormData, clearFormData } from "./actions";

class App extends Component {
  constructor() {
    super();
    this.state = {};
  }

  handleUpdateFormData = () => {
    this.store.dispatch(updateFormData);
  };

  handleClearFormData = () => {
    this.store.dispatch(clearFormData);
  };

  setFormData = (formData) => {
    this.setState(
      {
        formData: formData,
      },
      () => {
        console.log("State Updated. Redirect to Payment.");
      }
    );
  };

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <div className="App">
            <DefaultLayout>
              <Switch>
                <Route exact path="/payment" component={Checkout} />
                {/* <Route exact path="/calendar" component={DogCalendarForm} /> */}
                <Route exact path="/waterfowlwarmup" component={WaterfowlWarmupForm} />
                {/* <Route exact path="/template" component={TemplateForm} /> */}
                <Route exact path="/noviceshoot" component={NoviceShootForm} />
                <Route exact path="/tpfgeneral" component={TPFGeneralForm} />
                {/* <Route exact path="/pheasant" component={PheasantForm} /> */}
                {/* <Route exact path="/NWSGC" component={NWSGCForm} /> */}
                <Route path="/donation/:type/:amount" component={DonationForm} />
                <Route exact path="/error" component={Error} />
                <Route exact path="/success" component={Success} />
                <Route component={DonationForm} />
              </Switch>
            </DefaultLayout>
          </div>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
